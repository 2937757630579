import { STORAGE_PREFIX } from './../constants/constants';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const scrollToTop = (): void => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

interface CookieArgs {
  name: string;
  value: string;
  maxAge?: number;
  expires?: string;
}

export const saveToCookies = ({ name, value, maxAge, expires }: CookieArgs) => {
  let cookieString = `${STORAGE_PREFIX}${name}=${value};path=/;`;

  if (typeof maxAge === 'number') cookieString += 'max-age=' + maxAge + ';';
  if (expires) cookieString += 'expires=' + expires + ';';

  cookieString += 'samesite=lax';

  document.cookie = cookieString;
};

export const loadFromCookies = (name: string, withPrefix = true) => {
  if (!document || !document.cookie) return null;

  const prefix = `${withPrefix ? STORAGE_PREFIX : ''}${name}=`;
  const cookie = decodeURIComponent(document.cookie)
    .split(';')
    .find(c => c.includes(prefix));

  if (!cookie) return null;
  return cookie.split(prefix).reverse()[0];
};

export const removeFromCookies = (name: string) => {
  document.cookie = `${STORAGE_PREFIX}${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const saveToSessionStorage = (name: string, values: unknown) => {
  if (typeof window === 'undefined' || !window.sessionStorage) return;

  window.sessionStorage.setItem(`${STORAGE_PREFIX}${name}`, JSON.stringify(values));
};

export const loadFromSessionStorage = (name: string) => {
  if (typeof window === 'undefined' || !window.sessionStorage) return null;

  const serialized = window.sessionStorage.getItem(`${STORAGE_PREFIX}${name}`);

  return serialized !== null ? JSON.parse(serialized) : null;
};

export const removeSessionStorage = (name: string) => {
  if (typeof window === 'undefined' || !window.sessionStorage) return;

  window.sessionStorage.removeItem(`${STORAGE_PREFIX}${name}`);
  return null;
};

export const saveToLocalStorage = (name: string, values: unknown): void => {
  if (typeof window === 'undefined' || !window.localStorage) {
    return;
  }

  window.localStorage.setItem(`${STORAGE_PREFIX}${name}`, JSON.stringify(values));
};

export const loadFromLocalStorage = (name: string): string | null => {
  if (typeof window === 'undefined' || !window.localStorage) {
    return null;
  }

  const serialized = window.localStorage.getItem(`${STORAGE_PREFIX}${name}`);
  return serialized !== null ? JSON.parse(serialized) : null;
};

export const removeFromLocalStorage = (name: string, withoutPrefix?: boolean) => {
  if (typeof window === 'undefined' || !window.localStorage) {
    return;
  }

  window.localStorage.removeItem(`${withoutPrefix ? name : STORAGE_PREFIX + name}`);
};

export const convertDDMMYYYToYYYYMMDDIso = (date: string, hour?: string) => {
  const newdate = date.replace(/-/g, '/').split('/').reverse().join('/');
  const finalHour = hour ? hour.slice(0, 5) : '';
  const dateHour = hour ? `${newdate} ${finalHour}` : newdate;

  return new Date(`${dateHour} UTC`).toISOString();
};

export const convertDDMMYYYYToYYYYMMDDAsDate = (date: string) => {
  const [day, month, year] = date.split('/');
  return new Date(+year, +month - 1, +day);
};

export const handleResizeTextare = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  e.currentTarget.style.height = '';
  e.currentTarget.style.height = `${e.currentTarget.scrollHeight}px`;
};
///huyết áp
export const getBgColorHistoryMeasureSystoleAndDiastole = (systole: string, diastole: string) => {
  if (!systole || !diastole) return undefined;

  const systoleNumber = +systole;
  const diastoleNumber = +diastole;

  // Màu đỏ: >= 180/110
  if (systoleNumber >= 180 || diastoleNumber >= 110) return 'red';

  // Màu vàng: 160-179/100-109
  if (systoleNumber >= 160 || diastoleNumber >= 100) return 'yellow';

  // Màu vàng: 140-159/90-99
  if (systoleNumber >= 140 || diastoleNumber >= 90) return 'yellow';

  // Màu vàng: 130-139/85-89
  if (systoleNumber >= 130 || diastoleNumber >= 85) return 'yellow';

  // Màu xanh lá: < 130/85
  return 'green';
};

export const getBgColorHistoryMeasureSystole = (systole: string) => {
  if (!systole) return undefined;

  const systoleNumber = +systole;

  // Màu đỏ: >= 180
  if (systoleNumber >= 180) return 'red';

  // Màu vàng: 160-179
  if (systoleNumber >= 160) return 'yellow';

  // Màu vàng: 140-159
  if (systoleNumber >= 140) return 'yellow';

  // Màu vàng: 130-139
  if (systoleNumber >= 130) return 'yellow';

  // Màu xanh lá: < 130
  return 'green';
};

export const getBgColorHistoryMeasureDiastole = (diastole: string) => {
  if (!diastole) return undefined;
  const diastoleNumber = +diastole;

  // Màu đỏ: >= 110
  if (diastoleNumber >= 110) return 'red';

  // Màu vàng: 100-109
  if (diastoleNumber >= 100) return 'yellow';

  // Màu vàng: 90-99
  if (diastoleNumber >= 90) return 'yellow';

  // Màu vàng: 85-89
  if (diastoleNumber >= 85) return 'yellow';

  // Màu xanh lá: < 85
  return 'green';
};

//nhịp tim
export const getBgColorHistoryMeasureHeartRate = (heartRate: string) => {
  if (!heartRate) return undefined;

  const heartRateNumber = +heartRate;

  if (heartRateNumber > 100) return 'yellow';
  else if (heartRateNumber >= 60 && heartRateNumber <= 100) return 'green';

  return 'yellow';
};

export const getBgColorHistoryMeasureBloodSugar = (bloodSugar: string) => {
  if (!bloodSugar) return undefined;

  const bloodSugarNumber = +bloodSugar;

  // Màu đỏ: < 70 hoặc > 300
  if (bloodSugarNumber < 70 || bloodSugarNumber > 300) return 'red';

  // Màu vàng: >= 126
  if (bloodSugarNumber >= 126) return 'yellow';

  // Màu xanh lá: 100 - 125
  if (bloodSugarNumber >= 100 && bloodSugarNumber <= 125) return 'green';

  // Nếu không rơi vào các điều kiện trên, mặc định trả về màu xanh lá
  return 'green';
};

//BMI
export const getBgColorHistoryMeasureBMI = (bmi: number | string | undefined) => {
  if (!bmi) return undefined;

  if (Number(bmi) >= 30) return 'red';
  else if (Number(bmi) < 18.5 || (Number(bmi) >= 25 && Number(bmi) < 29.9)) return 'yellow';

  return 'green';
};

export const convertImageUrlToFile = async (url: string) => {
  if (!url) return undefined;

  try {
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split('.').pop();
    const filename = url.split('/').pop();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return new File([data], filename!, { type: `image/${ext}` });
  } catch {
    // No-op
  }
};

export const convertFullNameWithUpperFirstCharacterAfterEmpltyOne = (fullName: string) => {
  //if (!fullName) return undefined;

  // Split the input string by empty spaces
  const words = fullName.split(' ');

  // Capitalize the first character of each word
  const capitalizedWords = words.map(word => {
    if (word.length > 0) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    } else {
      return '';
    }
  });

  // Join the words back together with spaces
  //const finalText = capitalizedWords.join(' ');

  //uppercase
  const finalText = fullName.toUpperCase();
  return finalText;
};
